/*
Author:      Zachary Thomas
Created:     11/8/2022
Modified:    1/6/2023

Copyright 2022 - 2023 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect, useReducer } from "react";
import { API, FILTER_TYPES } from "../../utilities/constants";
import useApi from "../../hooks/useApi";
import BomTable from "./BomTable/BomTable";
import FilterOptions from "../../components/FilterOptions/FilterOptions";
import deepCopy from "../../utilities/deepCopy";
import Spinner from "../../components/Spinner/Spinner";
import FilterWrapper from "../../components/FilterWrapper/FilterWrapper";
import styles from "./DashboardPage.module.scss";

// Main page for using configurations to find BOMs.
export default function DashboardPage(): Component {
  const initialFilters = {
    application: { title: "Pump Application", name: "", code: null, options: null },
    model: { title: "Model", name: "", code: null, options: null },
    mountingConfig: { title: "Mounting Configuration", name: "", code: null, options: null },
    shaftExtension: { title: "Shaft Extension Type", name: "", code: null, options: null },
    impellerMaterial: { title: "Impeller Material", name: "", code: null, options: null },
    primingAddon: { title: "Redi-Prime Addons", name: "", code: null, options: null },
    voluteMaterial: { title: "Volute Material", name: "", code: null, options: null },
    sealMaterial: { title: "Seal Material", name: "", code: null, options: null },
    shaftMaterial: { title: "Shaft Material", name: "", code: null, options: null },
    shaftExtensionMaterial: {
      title: "Shaft Extension Material",
      name: "",
      code: null,
      options: null,
    },
    sleeveMaterial: { title: "Sleeve Material", name: "", code: null, options: null },
    suctionCoverMaterial: { title: "Suction Cover Material", name: "", code: null, options: null },
    suctionSpoolMaterial: { title: "Suction Spool Material", name: "", code: null, options: null },
    voluteFeature: { title: "Volute Type", name: "", code: null, options: null },
    impellerFeature: { title: "Impeller Configuration", name: "", code: null, options: null },
    bearingLubeFeature: { title: "Bearing Lube", name: "", code: null, options: null },
    sealingTypeFeature: { title: "Sealing Type", name: "", code: null, options: null },
    horsepowerFeature: { title: "Motor Horsepower", name: "", code: null, options: null },
    speedFeature: { title: "Motor Speed", name: "", code: null, options: null },
    voltageFeature: { title: "Motor Voltage", name: "", code: null, options: null },
    phaseFeature: { title: "Motor Phase", name: "", code: null, options: null },
    serviceFactorFeature: { title: "Motor Service Factor", name: "", code: null, options: null },
    enclosureFeature: { title: "Motor Enclosure", name: "", code: null, options: null },
    obsolete: { title: "Show Obsolete Pumps", name: "", code: null, options: null },
  };
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [boms, setBoms] = useState<Bom[]>([]);
  const [filterStore, dispatch] = useReducer(filterReducer, initialFilters);

  // Update the title displayed in the browser tab.
  useEffect(() => {
    document.title = "Cornell Configurator";
  }, []);

  // Get bill of materials that match the current filter selections.
  useApi(
    () => {
      const readyToQuery = filtersAreValid();
      setLoading(readyToQuery);
      return readyToQuery;
    },
    {
      method: "POST",
      url: `${API}/search/bom`,
      body: {
        application: filterStore.application.code,
        model: filterStore.model.code,
        mountingConfig: filterStore.mountingConfig.code,
        shaftExtension: filterStore.shaftExtension.code,
        impellerMaterial: filterStore.impellerMaterial.code,
        voluteMaterial: filterStore.voluteMaterial.code,
        sealMaterial: filterStore.sealMaterial.code,
        shaftMaterial: filterStore.shaftMaterial.code,
        shaftExtensionMaterial: filterStore.shaftExtensionMaterial.code,
        sleeveMaterial: filterStore.sleeveMaterial.code,
        suctionCoverMaterial: filterStore.suctionCoverMaterial.code,
        suctionSpoolMaterial: filterStore.suctionSpoolMaterial.code,
        voluteFeature: filterStore.voluteFeature.code,
        impellerFeature: filterStore.impellerFeature.code,
        bearingLubeFeature: filterStore.bearingLubeFeature.code,
        sealingTypeFeature: filterStore.sealingTypeFeature.code,
        motorHorsepowerFeature: filterStore.horsepowerFeature.code,
        motorSpeedFeature: filterStore.speedFeature.code,
        motorVoltageFeature: filterStore.voltageFeature.code,
        motorPhaseFeature: filterStore.phaseFeature.code,
        motorServiceFactorFeature: filterStore.serviceFactorFeature.code,
        motorEnclosureFeature: filterStore.enclosureFeature.code,
        showObsolete: filterStore.obsolete.code,
        primingAddon: filterStore.primingAddon.code
      },
    },
    async (response: Response, responseBody: ResponseBody) => {
      setLoading(false);
      if (response.ok && responseBody) {
        setBoms(responseBody.pumps);
        setErrorMessage("");
      } else {
        setBoms([]);
        setErrorMessage("Internal server error. Failed to retrieve BOMs.");
      }
    },
    [JSON.stringify(filterStore)]
  );

  // Check if filters are valid.
  function filtersAreValid(): boolean {
    // Confirm that at least one filter is filled out.
    const filters = Object.keys(filterStore).map((filterKey) => filterStore[filterKey]);
    let filterCount = 0;
    filters.forEach((filter) => {
      if (filter.code !== null && filter.code.length > 0) {
        filterCount += 1;
      }
    });

    if (filterCount === 0) {
      setErrorMessage("At least one filter must be selected.");
      return false;
    }

    // Confirm that each filters has a valid entry or no entry at all.
    let allFiltersAreValid = true;
    filters.forEach((filter) => {
      let matchingOptionFound = false;
      if (filter.options !== null) {
        matchingOptionFound = filter.options.some((option) => option.name === filter.name);
      }
      if (!matchingOptionFound && filter.name.length > 0) {
        setErrorMessage(`A valid ${filter.title.toLowerCase()} must be selected`);
        allFiltersAreValid = false;
      }
    });

    return allFiltersAreValid;
  }

  // Reducer for filter information.
  function filterReducer(state: FilterStore, action: Action): FilterStore {
    switch (action.type) {
      case FILTER_TYPES.SET_FILTERS: {
        const stateDeepCopy = deepCopy(state);
        const validFilterKeys = Object.keys(stateDeepCopy);
        const keyName = action.payload.keyName;
        const filters = action.payload.filters;
        if (keyName !== undefined && filters !== undefined && validFilterKeys.includes(keyName)) {
          stateDeepCopy[keyName].options = filters;
        }
        return stateDeepCopy;
      }

      case FILTER_TYPES.CLEAR_FILTER: {
        const stateDeepCopy = deepCopy(state);
        const validFilterKeys = Object.keys(stateDeepCopy);
        const keyName = action.payload.keyName;

        // See if we can find a match on the current filter.
        if (keyName !== undefined && validFilterKeys.includes(keyName)) {
          stateDeepCopy[keyName].name = "";
        }
        return stateDeepCopy;
      }

      case FILTER_TYPES.CLEAR_ALL_FILTERS: {
        const stateDeepCopy = deepCopy(state);
        const validFilterKeys = Object.keys(stateDeepCopy);
        validFilterKeys.forEach((keyName) => {
          stateDeepCopy[keyName].code = null;
          stateDeepCopy[keyName].name = "";
        });
        setBoms([]);
        return stateDeepCopy;
      }

      case FILTER_TYPES.SELECT_FILTER: {
        const stateDeepCopy = deepCopy(state);
        const validFilterKeys = Object.keys(stateDeepCopy);
        const keyName = action.payload.keyName;
        const filterName = action.payload.filterName;

        // See if we can find a match on the current filter.
        if (
          keyName !== undefined &&
          filterName !== undefined &&
          validFilterKeys.includes(keyName)
        ) {
          const selectedFilter = stateDeepCopy[keyName];
          selectedFilter.name = filterName;

          // Look through the filters options to see if there is a match for the current filter name.
          if (selectedFilter.options !== null) {
            selectedFilter.code = null;
            selectedFilter.options.forEach((option) => {
              if (option.name === filterName) {
                selectedFilter.code = option.code;
              }
            });
          }
        }
        return stateDeepCopy;
      }

      default: {
        return state;
      }
    }
  }

  return (
    <div className="p-4 row">
      <Spinner loading={loading} />

      <div className={`${styles.scrollSection} col-12 col-lg-4`}>
        <button
          className="btn btn-primary mb-4 w-100"
          onClick={() =>
            dispatch({
              type: FILTER_TYPES.CLEAR_ALL_FILTERS,
              payload: {},
            })
          }
        >
          Clear all filters
        </button>

        <FilterOptions
          filter={filterStore.application}
          type="application"
          endpoint="application"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.model}
          type="model"
          endpoint="model"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.mountingConfig}
          type="mountingConfig"
          endpoint="mountingConfig"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.bearingLubeFeature}
          type="bearingLubeFeature"
          endpoint="feature/bearingLube"
          showOptions={
            filterStore.mountingConfig.code !== null &&
            filterStore.mountingConfig.name !== "CC" &&
            filterStore.mountingConfig.name !== "VM" &&
            filterStore.mountingConfig.name !== "VM Immersible"
          }
          onAction={(action) => dispatch(action)}
        />

        <FilterWrapper
          title="Motor Details"
          showWrapper={
            filterStore.mountingConfig.name !== "EM16" &&
            filterStore.mountingConfig.name !== "EM18" &&
            filterStore.mountingConfig.name !== "EM18DB"
          }
          childTypes={[
            "horsepowerFeature",
            "speedFeature",
            "voltageFeature",
            "phaseFeature",
            "serviceFactorFeature",
            "enclosureFeature",
          ]}
          onAction={(action) => dispatch(action)}
        >
          <FilterOptions
            filter={filterStore.horsepowerFeature}
            type="horsepowerFeature"
            endpoint="feature/motorHorsepower"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />

          <FilterOptions
            filter={filterStore.speedFeature}
            type="speedFeature"
            endpoint="feature/motorSpeed"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />

          <FilterOptions
            filter={filterStore.voltageFeature}
            type="voltageFeature"
            endpoint="feature/motorVoltage"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />

          <FilterOptions
            filter={filterStore.phaseFeature}
            type="phaseFeature"
            endpoint="feature/motorPhase"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />

          <FilterOptions
            filter={filterStore.serviceFactorFeature}
            type="serviceFactorFeature"
            endpoint="feature/motorServiceFactor"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />

          <FilterOptions
            filter={filterStore.enclosureFeature}
            type="enclosureFeature"
            endpoint="feature/motorEnclosure"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />
        </FilterWrapper>

        <FilterWrapper
          title="Shaft Extension"
          showWrapper={
            filterStore.mountingConfig.name === "CC" ||
            filterStore.mountingConfig.name === "VM" ||
            filterStore.mountingConfig.name === "VM Immersible"
          }
          childTypes={["shaftExtension", "shaftExtensionMaterial"]}
          onAction={(action) => dispatch(action)}
        >
          <FilterOptions
            filter={filterStore.shaftExtension}
            type="shaftExtension"
            endpoint="shaftExtension"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />

          <FilterOptions
            filter={filterStore.shaftExtensionMaterial}
            type="shaftExtensionMaterial"
            endpoint="material/shaftExtension"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />
        </FilterWrapper>

        <FilterOptions
          filter={filterStore.shaftMaterial}
          type="shaftMaterial"
          endpoint="material/shaft"
          showOptions={
            filterStore.mountingConfig.name !== "CC" &&
            filterStore.mountingConfig.name !== "VM" &&
            filterStore.mountingConfig.name !== "VM Immersible"
          }
          onAction={(action) => dispatch(action)}
        />

        <FilterWrapper
          title="Impeller Details"
          showWrapper={true}
          childTypes={["impellerFeature", "impellerMaterial"]}
          onAction={(action) => dispatch(action)}
        >
          <FilterOptions
            filter={filterStore.impellerFeature}
            type="impellerFeature"
            endpoint="feature/impeller"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />

          <FilterOptions
            filter={filterStore.impellerMaterial}
            type="impellerMaterial"
            endpoint="material/impeller"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />
        </FilterWrapper>

        <FilterWrapper
          title="Volute Details"
          showWrapper={true}
          childTypes={["voluteFeature", "voluteMaterial"]}
          onAction={(action) => dispatch(action)}
        >
          <FilterOptions
            filter={filterStore.voluteFeature}
            type="voluteFeature"
            endpoint="feature/volute"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />

          <FilterOptions
            filter={filterStore.voluteMaterial}
            type="voluteMaterial"
            endpoint="material/volute"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />
        </FilterWrapper>

        <FilterWrapper
          title="Seal Details"
          showWrapper={true}
          childTypes={["sealingTypeFeature", "sealMaterial"]}
          onAction={(action) => dispatch(action)}
        >
          <FilterOptions
            filter={filterStore.sealingTypeFeature}
            type="sealingTypeFeature"
            endpoint="feature/sealingType"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />

          <FilterOptions
            filter={filterStore.sealMaterial}
            type="sealMaterial"
            endpoint="material/seal"
            showOptions={true}
            smallContainer={true}
            onAction={(action) => dispatch(action)}
          />
        </FilterWrapper>

        <FilterOptions
          filter={filterStore.sleeveMaterial}
          type="sleeveMaterial"
          endpoint="material/sleeve"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.suctionCoverMaterial}
          type="suctionCoverMaterial"
          endpoint="material/suctionCover"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.suctionSpoolMaterial}
          type="suctionSpoolMaterial"
          endpoint="material/suctionSpool"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />
        <FilterOptions
          filter={filterStore.primingAddon}
          type="primingAddon"
          endpoint="feature/primingaddon"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />

        <FilterOptions
          filter={filterStore.obsolete}
          type="obsolete"
          endpoint="obsolete"
          showOptions={true}
          onAction={(action) => dispatch(action)}
        />
      </div>

      <div className="col-12 col-lg-8">
        <div className={styles.scrollSection}>
          <BomTable errorMessage={errorMessage} boms={boms} filterStore={filterStore} />
        </div>
      </div>
    </div>
  );
}

interface ResponseBody {
  pumps: Bom[];
}
